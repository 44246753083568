import { IntlMessageFormat } from 'intl-messageformat';
import { type ReactNode, cloneElement, isValidElement } from 'react';

import consent from './data/de/consent.json';
import error from './data/de/error.json';
import login from './data/de/login.json';
import scope from './data/de/scope.json';
import consentEn from './data/en/consent.json';
import errorEn from './data/en/error.json';
import loginEn from './data/en/login.json';
import scopeEn from './data/en/scope.json';

interface NestedTranslationData {
  [key: string]: string | NestedTranslationData;
}
type TranslationData = Record<string, NestedTranslationData>;

const translationData = {
  de: {
    login,
    consent,
    error,
    scope,
  },
  en: {
    login: loginEn,
    consent: consentEn,
    error: errorEn,
    scope: scopeEn,
  },
};
type RealTranslationData = (typeof translationData)['de'];

type Dot<T extends string, U extends string> = '' extends U ? T : `${T}.${U}`;
type GetKeys<T> = T extends string
  ? ''
  : {
      [K in keyof T & string]: T[K] extends string ? K : Dot<K, GetKeys<T[K]>>;
    }[keyof T & string];

export type TranslationKey<T extends keyof RealTranslationData> = GetKeys<RealTranslationData[T]>;
type RichTranslationValues = Record<string, ReactNode | ((chunks: ReactNode[]) => ReactNode)>;

function getLanguage(): 'de' | 'en' {
  return document.getElementsByTagName('html')[0].getAttribute('lang') as 'de' | 'en';
}

export function getTranslationData(): TranslationData {
  const lang = getLanguage();
  if (!(lang in translationData)) {
    return translationData.de;
  }

  // eslint-disable-next-line security/detect-object-injection
  return translationData[lang];
}

interface TranslationFunction<T extends keyof RealTranslationData> {
  (key: TranslationKey<T>, args?: Record<string, string>): string;

  rich(
    key: TranslationKey<T>,
    args: Record<string, ReactNode | ((chunks: ReactNode[]) => ReactNode)>,
  ): ReactNode;
}

export function useTranslations<T extends keyof RealTranslationData>(
  scope: T,
): TranslationFunction<T> {
  // eslint-disable-next-line security/detect-object-injection
  const data = getTranslationData()[scope];

  function extractTranslation(key: TranslationKey<T>): string {
    const parts = key.split('.');
    const firstPart = parts.shift();
    if (firstPart === undefined) {
      throw new Error('Invalid translation key');
    }
    // eslint-disable-next-line security/detect-object-injection
    let currentPart: string | NestedTranslationData | undefined = data[firstPart];
    while (parts.length > 0 && typeof currentPart !== 'string') {
      const nextPart = parts.shift();
      if (nextPart === undefined) {
        throw new Error('Next part cannot be undefined');
      }
      // eslint-disable-next-line security/detect-object-injection
      currentPart = currentPart[nextPart];
    }

    return currentPart as string;
  }

  const ret = (key: TranslationKey<T>, args?: Record<string, string>): string => {
    const currentPart = extractTranslation(key);
    return args
      ? (new IntlMessageFormat(currentPart, getLanguage()).format(args) as string)
      : currentPart;
  };

  ret.rich = function (key: TranslationKey<T>, args: RichTranslationValues): ReactNode {
    const currentPart = extractTranslation(key);
    return new IntlMessageFormat(currentPart, getLanguage()).format(prepareTranslationValues(args));
  };

  return ret;
}

// Workaround https://github.com/formatjs/formatjs/issues/1467
function prepareTranslationValues(values: RichTranslationValues): RichTranslationValues {
  const ret: RichTranslationValues = {};
  for (const key of Object.keys(values)) {
    let index = 0;
    // eslint-disable-next-line security/detect-object-injection
    const value = values[key];

    let transformed: ReactNode | ((chunks: ReactNode[]) => ReactNode);
    if (typeof value === 'function') {
      transformed = (chunks: ReactNode[]) => {
        const result = value(chunks);

        return isValidElement(result) ? cloneElement(result, { key: key + index++ }) : result;
      };
    } else {
      transformed = value;
    }

    // eslint-disable-next-line security/detect-object-injection
    ret[key] = transformed;
  }

  return ret;
}
