'use client';

import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import { EPPlainButton, type EPPlainButtonProps } from './EPPlainButton';
import { assertUnreachable } from './utils';

interface EPButtonProps {
  href?: string;
  externalLink?: string;
  children?: ReactNode;
  iconBefore?: ReactNode | IconDefinition;
  iconAfter?: ReactNode | IconDefinition;
  value?: string;
  name?: string;
  /**
   * default: primary
   */
  mode?: 'tertiary' | 'primary' | 'ghost' | 'secondary' | 'warning';
  /**
   * default: md
   */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  /**
   * default: Link
   */
  element?: 'a' | 'button' | 'submit' | 'Link';
  disabled?: boolean;
  active?: boolean;
  onClick?: () => unknown;
  className?: string;
  textColorClassName?: string;
  backgroundClassName?: string;
  activeBackgroundClassName?: string;
}

type Props = EPPlainButtonProps & EPButtonProps;

export function EPButton(props: Props) {
  const mode = props.mode ?? 'primary';
  const active = props.active ?? false;

  let className = props.disabled ? 'cursor-default' : 'cursor-pointer';
  let textColor = props.textColorClassName ?? null;
  let activeBackground = props.activeBackgroundClassName ?? null;
  let background = props.backgroundClassName ?? null;

  if (props.disabled) {
    switch (mode) {
      case 'primary':
      case 'secondary':
      case 'warning':
        className += ' bg-epmspacedust-300';
        textColor ??= 'text-white';
        break;
      case 'tertiary':
        className += ' border-2 border-epmspacedust-300 bg-transparent';
        textColor ??= 'text-epmspacedust-300';
        break;
      case 'ghost':
        className += ' bg-transparent';
        textColor ??= 'text-epmspacedust-300';
        break;
      default:
        assertUnreachable(mode);
    }
  } else {
    switch (mode) {
      case 'primary':
        textColor ??= 'text-white';
        background ??= clsx(
          !active && 'from-epgradientstart to-epgradientend',
          'bg-gradient-to-r hover:from-epinfrapurple hover:to-epinfrapurple',
        );
        activeBackground ??= clsx(
          active && 'from-epminfrapurple-600 to-epminfrapurple-600',
          'active:from-epminfrapurple-600 active:to-epminfrapurple-600',
        );
        break;
      case 'secondary':
        textColor ??= 'text-white';
        background ??= clsx(!active && 'bg-epinfrapurple', 'hover:bg-epminfrapurple-400 ');
        activeBackground ??= clsx(
          active && 'bg-epminfrapurple-600',
          'active:bg-epminfrapurple-600',
        );
        break;
      case 'tertiary':
        textColor ??= (active ? 'text-white' : 'text-epinfrapurple') + ' active:text-white';
        background ??= clsx(
          !active && 'bg-transparent',
          'border-2 border-epinfrapurple hover:bg-epminfrapurple-100',
        );
        activeBackground ??= clsx(
          active && 'bg-epminfrapurple-600',
          'active:bg-epminfrapurple-600',
        );
        break;
      case 'ghost':
        textColor ??= (active ? 'text-white' : 'text-epinfrapurple') + ' active:text-white';
        background ??= clsx(!active && 'bg-transparent', 'hover:bg-epminfrapurple-100');
        activeBackground ??= clsx(
          active ? 'bg-epminfrapurple-600' : 'bg-transparent',
          'active:bg-epminfrapurple-600',
        );
        break;
      case 'warning':
        textColor ??= 'text-white';
        background ??= clsx(!active && 'bg-epmars', 'hover:bg-epmmars-400');
        activeBackground ??= clsx(active && 'bg-epmmars-600', 'active:bg-epmmars-600');
        break;
      default:
        assertUnreachable(mode);
    }
  }

  return (
    <EPPlainButton
      {...props}
      className={clsx(props.className, className, textColor, activeBackground, background)}
    />
  );
}
