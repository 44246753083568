import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import { assertUnreachable } from './utils';

interface SharedProps {
  children?: ReactNode;
  iconBefore?: ReactNode | IconDefinition;
  iconAfter?: ReactNode | IconDefinition;
  /**
   * default: md
   */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  /**
   * default: Link
   */
  element?: 'a' | 'button' | 'submit' | 'Link';
  disabled?: boolean;
  onClick?: () => unknown;
  className?: string;
}

interface ButtonProps extends SharedProps {
  element: 'button' | 'submit';
  value?: string;
  name?: string;
}

interface LinkProps extends SharedProps {
  element?: 'Link' | 'a';
  href?: string;
  externalLink?: string;
}

export type EPPlainButtonProps = ButtonProps | LinkProps;

export function EPPlainButton({ size = 'md', ...props }: EPPlainButtonProps) {
  let paddingClassName = '';

  if (props.children) {
    switch (size) {
      case 'xs':
        paddingClassName = 'px-5 py-2';
        break;
      case 'sm':
        paddingClassName = 'px-8 py-3';
        break;
      case 'md':
        paddingClassName = 'px-10 py-5';
        break;
      case 'lg':
        paddingClassName = 'px-16 py-7';
        break;
      default:
        assertUnreachable(size);
    }
  } else {
    paddingClassName += ' p-3';
  }

  const roundedClassName = props.children ? 'rounded-[6rem]' : 'rounded-full';
  const commonClassName =
    'inline-flex justify-center items-center gap-3 text-sm font-medium transition-all duration-150';

  const classNames = clsx(commonClassName, paddingClassName, roundedClassName);

  if (props.element === 'button' || props.element === 'submit') {
    return (
      <button
        onClick={props.onClick}
        className={clsx(props.className, classNames)}
        disabled={props.disabled}
        type={props.element === 'submit' ? 'submit' : 'button'}
        name={props.name}
        value={props.value}
      >
        <Icon icon={props.iconBefore} />
        {props.children}
        <Icon icon={props.iconAfter} />
      </button>
    );
  } else if (props.element === undefined || props.element === 'Link') {
    const linkHref = props.href ?? props.externalLink;
    return (
      <a
        href={linkHref}
        target={props.externalLink ? '_blank' : ''}
        onClick={props.onClick}
        className={clsx(props.className, classNames)}
      >
        <Icon icon={props.iconBefore} />
        {props.children}
        <Icon icon={props.iconAfter} />
      </a>
    );
  } else {
    const linkProps = props as LinkProps;
    const link = linkProps.href ?? linkProps.externalLink;
    return (
      <a
        href={link}
        target={linkProps.externalLink ? '_blank' : ''}
        onClick={props.onClick}
        className={clsx(props.className, classNames)}
      >
        <Icon icon={props.iconBefore} />
        {props.children}
        <Icon icon={props.iconAfter} />
      </a>
    );
  }
}

function Icon({ icon }: { icon?: IconDefinition | ReactNode }) {
  if (!icon) {
    return null;
  }

  if (isFontawesomeIconDefinition(icon)) {
    return <FontAwesomeIcon icon={icon} className="size-4" />;
  }

  return <>{icon}</>;
}

function isFontawesomeIconDefinition(icon: unknown): icon is IconDefinition {
  return 'icon' in (icon as IconDefinition);
}
