import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons/faArrowLeftLong';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons/faArrowRightLong';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useRef } from 'react';

import { AutocompleteInput } from '../common/AutocompleteInput/AutocompleteInput';
import { Divider } from '../common/Divider';
import { EPButton } from '../common/EPButton';
import { Subtitle, Title } from '../common/Headlines';
import { InputGroup } from '../common/InputGroup';
import { Label } from '../common/Label';
import { type TranslationKey, useTranslations } from '../intl/useTranslations';
import { getLoginChallenge } from '../store/challenges';
import { getServerSideErrors } from '../store/error';
import type { RememberedSchool } from '../store/schools';
import type { SearchedSchool } from './autocomplete';
import { useSearchableSchool } from './hooks/useSearchableSchool';

interface Props {
  hasRememberedSchools: boolean;
  onSelectOverview: () => void;
  onAddRememberedSchool: (school: RememberedSchool) => void;
  className?: string;
}

export function SearchSchool({
  hasRememberedSchools,
  onSelectOverview,
  onAddRememberedSchool,
  className,
}: Props) {
  const t = useTranslations('login');

  const {
    error,
    selectedEmail,
    selectedSchool,
    searchByMail,
    searchByName,
    setSelectedEmail,
    setSelectedSchool,
  } = useSearchableSchool({
    error: getServerSideErrors() ? t(getServerSideErrors() as TranslationKey<'login'>) : null,
  });
  const formRef = useRef<HTMLFormElement>(null);

  function selectEmail(email: (SearchedSchool & { email: string }) | null): void {
    setSelectedEmail(email);
    rememberSchool(email);
    // Wait for react to propagate state change
    setTimeout(() => formRef.current?.submit(), 0);
  }
  function selectSchool(school: SearchedSchool | null): void {
    setSelectedSchool(school);
    rememberSchool(school);
    // Wait for react to propagate state change
    setTimeout(() => formRef.current?.submit(), 0);
  }

  function rememberSchool(school: SearchedSchool | null) {
    if (!school) {
      return;
    }
    onAddRememberedSchool({
      identityProviderId: school.identityProviderId,
      name: school.name,
    });
  }

  return (
    <div className={clsx(className, 'flex flex-col justify-between gap-10')}>
      <div className="flex flex-col gap-2">
        <Title>{t('search.title')}</Title>
        <Subtitle>{t('search.subtitle')}</Subtitle>
      </div>
      {error && <div className="w-0 min-w-full text-epmars">{error}</div>}
      <div>
        <InputGroup>
          <Label forId="input-school" className="text-white md:text-epspacedust">
            {t('search.school')}
          </Label>
          <AutocompleteInput
            id="input-school"
            search={searchByName}
            onSelectValue={selectSchool}
            formatSearchResult={(result) => (
              <div className="flex items-center justify-between">
                <div>
                  <div className="font-medium">{result.name}</div>
                  <div className="text-sm text-epmspacedust-400">
                    {result.address
                      ? `${result.address.zipCode} ${result.address.city}`
                      : result.mailDomain}
                  </div>
                </div>
                <FontAwesomeIcon icon={faArrowRightLong} className="ml-2 size-4 text-epspacedust" />
              </div>
            )}
          />
        </InputGroup>
        <Divider>{t('search.or')}</Divider>
        <InputGroup>
          <Label forId="input-email" className="text-white md:text-epspacedust">
            {t('search.email')}
          </Label>
          <AutocompleteInput
            id="input-email"
            search={searchByMail}
            onSelectValue={selectEmail}
            formatSearchResult={(result) => (
              <div className="flex items-center justify-between">
                <div>
                  <div className="font-medium">{result.name}</div>
                  <div className="text-sm text-epmspacedust-400">{result.email}</div>
                </div>
                <FontAwesomeIcon icon={faArrowRightLong} className="ml-2 size-4 text-epspacedust" />
              </div>
            )}
          />
        </InputGroup>
      </div>
      <form
        className="flex items-center justify-stretch gap-2"
        action="/login/confirm"
        method="post"
        ref={formRef}
      >
        {hasRememberedSchools && (
          <>
            <EPButton
              className="grow items-center justify-center max-md:hidden"
              mode="ghost"
              onClick={onSelectOverview}
              iconBefore={faArrowLeftLong}
            >
              {t('back')}
            </EPButton>
            <EPButton
              className="grow items-center justify-center md:hidden"
              textColorClassName="text-white hover:text-epinfrapurple"
              mode="ghost"
              onClick={onSelectOverview}
              iconBefore={faArrowLeftLong}
            >
              {t('back')}
            </EPButton>
          </>
        )}
        {selectedEmail && <input type="hidden" name="mail" value={selectedEmail.email} />}
        {selectedSchool && (
          <input type="hidden" name="school" value={selectedSchool.identityProviderId} />
        )}
        <input type="hidden" name="login_challenge" value={getLoginChallenge()} />
      </form>
    </div>
  );
}
